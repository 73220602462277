$primary: #45b9e7;
$secondary: #000000;
.ContainerOfBoxes {
  display: flex;
  width: 100%;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-evenly;
  align-items: flex-start;
}
.boxContentQuestion:hover {
  box-shadow: none !important;
}
.boxContent.dark {
  background-color: black;
  a {
    color: white;
  }
}
.Large {
  width: 90% !important;
}
.boxContent {
  border: 2px solid rgb(220, 220, 220);
  height: auto;
  color: black;
  background-color: white;
  padding: 20px 20px 20px 20px;
  margin-top: 3vh;
  margin-bottom: 3vh;
  width: 45%;
  margin: 10px;
  border-radius: 10px;
  transition: 300ms;

  a {
    color: black;
    text-decoration: none;
  }
  h1 {
    justify-content: center;
  }

  .boxP {
    margin-top: 2px;
  }
  .buttons {
    display: flex;
    justify-content: center;
  }
  @media screen and (max-width: 650px) {
    .buttons {
      flex-wrap: wrap;
    }
  }
  .buttonQuestion {
    border: 2px solid transparent;
    margin-left: 3px;
    margin-right: 3px;
    border-radius: 10px;
    transition: 300ms;
    height: 60px;
    width: 50px;
    // padding-left: 10px;
    // padding-right: 10px;
  }
  .LargeButton {
    width: 200px;
    height: 70px;
  }
  .Larger {
    img {
      max-width: 150px;
    }
  }
  .buttonQuestion:active {
    transform: scale(0.8);
  }
  .ImageBlock {
    display: block;
  }
  .buttonQuestion:hover {
    border: 2px solid black;
    transition: 300ms border;
  }
  .buttonQuestion:focus {
    outline: none;
  }
  .correctButton {
    background-color: green;
    color: white;
  }
  .incorrectButton {
    background-color: red;
  }
  .chosen {
    background-color: rgb(185, 185, 185);
  }
  .button {
    background-color: white;
    border: 2px solid black;
    padding: 5px;
    border-radius: 10px;
    transition: 300ms;
    a {
      text-decoration: none;
      color: black;
      font-size: 15px;
    }
  }
  .button:focus {
    outline: none;
  }

  .button:hover {
    background-color: black;
    transition: 300ms;
    a {
      color: white;
    }
  }
}
.boxContent:hover {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}
@media screen and (max-width: 950px) {
  .boxContent {
    width: 40%;
  }
}
@media screen and (max-width: 650px) {
  .boxContent {
    width: 100%;
  }
}
.explanationOpen {
  display: inline-block;
  color: black;
  transition: 300ms;
  cursor: pointer;
  width: auto;
  margin-left: 3px;
}
.explanationOpen:hover {
  color: rgb(82, 82, 244);
}
.explanation {
  display: none;
  margin-top: 10px;
  margin-left: 3px;
}
.explanation.opened {
  display: flex;
  justify-content: center;
  margin-top: 10px;
}
.hint {
  float: right;
  transition: 200ms;
  cursor: pointer;
}
.hint:hover {
  color: rgb(82, 82, 244);
}
.noHint {
  display: none;
}
.clue:hover {
  color: black;
}
.clue {
  position: absolute;
  right: 0px;
  top: 15px;
  display: none;
}
.clue.opened {
  border: 2px solid black;
  background-color: white;
  border-radius: 10px;
  padding: 10px;
  display: inline;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}
.boxTop {
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.submitButton {
  border: 2px solid black;
  margin-left: 20px;
  padding: 5px;
  position: relative;
  background-color: white;
  z-index: 10;
  cursor: pointer;
}
.submitButton.invalid {
  pointer-events: none;
}
.submitButton:focus {
  border: 2px solid black;
  outline: none;
}
.submitButton:active {
  background-color: rgba(82, 82, 244, 0.5);
  color: white;
  -webkit-box-shadow: 0px 2px 26px -5px rgba(82, 82, 244, 1);
  box-shadow: 0px 2px 26px -5px rgba(82, 82, 244, 1);
}
.inputBox {
  margin-top: 10px;
  display: flex;
  justify-content: center;
}

.incorrect {
  color: red;
}
.correct {
  color: green;
}
.questionBox {
  display: flex;
  justify-content: center;
  @media screen and (min-width: 1001px) {
    img {
      max-width: 500px;
    }
  }

  @media screen and (max-width: 1000px) {
    img {
      max-width: 300px;
    }
  }
}

.InputField {
  width: 100px;
  padding: 5px;
  border: 2px solid black;
}
.InputField:focus {
  border: 2px solid rgba(82, 82, 244, 0.5);
  outline: none;
  -webkit-box-shadow: 0px 2px 26px -5px rgba(82, 82, 244, 0.5);
  box-shadow: 0px 2px 26px -5px rgba(82, 82, 244, 0.5);
}

.clearQuestions {
  border: 2px solid black;
  padding: 20px;
  position: relative;
  background-color: white;
  z-index: 10;
  width: 200px;
  height: 75px;
  border-radius: 10px;
  margin-bottom: 50px;
  cursor: pointer;
  transition: 150ms;
  p {
    font-size: 20px;
    opacity: 1;

    z-index: 13;
    transition: 200ms;
  }
}
.clearQuestions::after {
  content: '';
  position: absolute;
  height: 0%;
  width: 100%;
  bottom: 0;
  background-color: rgb(82, 82, 244);
  left: 0;
  z-index: -1;
  transition: 200ms;

  border-radius: 9px;
}
.clearQuestions:hover {
  p {
    color: white;
  }
}

.clearQuestions:hover::after {
  height: 100%;
}
.clearQuestions:active::after {
  background-color: rgb(11, 11, 173);
}
.clearQuestions:focus {
  outline: 0;
}
.disabledInput {
  pointer-events: none !important;
}
