$primary: #45b9e7;
$secondary: #000000;
@media screen and (min-width: 1007px) {
  header {
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  }
  header:hover {
    background-color: rgb(82, 82, 244);
    a {
      color: white;
    }
    a:hover {
      color: rgb(5, 5, 104);
    }
  }
  header.dark {
    background-color: rgb(0, 0, 0);
    a {
      color: white;
    }
    a:hover {
      color: rgb(161, 161, 161);
    }
    h2 {
      color: white;
    }
  }

  .navLinks {
    margin-right: 1vw;

    width: 20%;
    display: flex;
    justify-content: space-evenly;
    .navLink {
      font-size: 2.7vh;

      border-radius: 15px;
      padding: 10px;

      cursor: pointer;
    }
  }
  .mobileBurger {
    display: none;
  }
}

@media screen and (max-width: 1007px) {
  .navLink {
    display: none;
    position: absolute;
  }
  .mobileBurger {
    margin-right: 1vw;
    cursor: pointer;
  }
  header {
    border: none;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  }
}
.mobileNavLinks {
  background-color: white;
  max-height: 0px;
  display: none;
}
.mobileNavLinks.active {
  max-height: 300px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-bottom: 2px solid black;

  header {
    border-bottom: none;
  }
  a {
    font-size: 25px;
    text-decoration: 2px solid underline;
  }
}
a {
  text-decoration: none;
  color: $secondary;
}

header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 10vh;

  // position: fixed;
  // top: 0px;

  color: $secondary;
  background-color: white;
}

.navBrand {
  margin-left: 1vw;
}
