.buttonCalc {
  background-color: white;
  border: 2px solid black;
  padding: 5px;
  border-radius: 10px;
  transition: 300ms;
  a {
    text-decoration: none;
    color: black;
    font-size: 15px;
  }
}
.buttonCalc:hover {
  background-color: black;
  transition: 300ms;
  a {
    color: white;
  }
}
