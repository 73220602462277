@media screen and (max-width: 900px) {
  .aboutContainer {
    width: 100%;
  }
}
.text {
  margin-left: 10%;
  margin-right: 10%;
}
.linked {
  color: rgb(82, 82, 244);
  text-decoration: underline dotted;
}
.linked:visited {
  color: rgb(21, 21, 149);
}

.aboutContainer {
  background-color: white;
  border-radius: 10px;
  border: 2px solid black;
  width: 80%;
  margin-top: 2vh;
  margin-left: auto;
  margin-right: auto;
}
.section {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  flex-direction: column;
  li {
    font-size: 20px;
    text-decoration: underline 2px solid rgba(82, 82, 244, 0.5);
  }
  @media screen and (max-width: 900px) {
    .form {
      display: none;
    }
  }
  @media screen and (max-width: 550px) {
    li {
      font-size: 15px;
    }
  }
}
.todo {
  ol {
    list-style-position: inside;
  }
}
